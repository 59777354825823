import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";

const AboutUsSection1 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <section className="relative bg-tertiary-50 pt-12 md:pt-0">
      <div className="container">
        <div className="grid gap-y-12 md:grid-cols-2">
          <div className="md:py-24 md:pr-10 lg:py-36 lg:pr-20">
            <h1>{sectionTitle}</h1>
            <p>{sectionDesc}</p>
            <ButtonSolid modal="modal-contact" text="Contact" />
          </div>
          <div className="relative -mx-4 md:mx-0 md:h-full">
            <div className="hidden h-full md:absolute md:left-0 md:block md:w-[50vw]">
              <StaticImage
                src="../../images/4.0 About/1.0 About-hero.jpg"
                loading="eager"
                placeholder="none"
                className="h-full w-full"
              />
            </div>

            <div className="md:hidden">
              <StaticImage
                src="../../images/4.0 About/1.0 About-mobile.jpg"
                loading="eager"
                placeholder="none"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsSection1;
