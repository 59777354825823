import React from "react";

import { StaticImage } from "gatsby-plugin-image";

const AboutUsSection2 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <>
      <section className="relative mb-20 bg-[#08353D] py-28 md:mb-32 md:py-36">
        <div className="container">
          <header className="mx-auto max-w-[776px] text-center">
            <h2 className="text-white">{sectionTitle}</h2>
            <p className="mb-0 text-white">{sectionDesc}</p>
          </header>
        </div>

        <div className="absolute top-1 left-0">
          <StaticImage
            src="../../images/4.0 About/missiontopleft.png"
            alt="Mission Heading Top Left"
            loading="eager"
            placeholder="none"
            className="h-full w-full"
            imgClassName="object-top"
          />
        </div>
        <div className="absolute top-1 right-0">
          <StaticImage
            src="../../images/4.0 About/missiontopright.png"
            alt="Mission Heading Top Right"
            loading="eager"
            placeholder="none"
            className="h-full w-full"
            imgClassName="object-top"
          />
        </div>
        <div className="absolute bottom-1 right-0">
          <StaticImage
            src="../../images/4.0 About/missionbottomright.png"
            alt="Mission Heading Bottom Right"
            loading="eager"
            placeholder="none"
            className="h-full w-full"
            imgClassName="object-top"
          />
        </div>
        <div className="absolute bottom-1 left-0">
          <StaticImage
            src="../../images/4.0 About/missionbottomleft.png"
            alt="Mission Heading Bottom Left"
            loading="eager"
            placeholder="none"
            className="h-full w-full"
            imgClassName="object-top"
          />
        </div>
      </section>
    </>
  );
};

export default AboutUsSection2;
